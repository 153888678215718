import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import ConnectButton from './ConnectButton'
import InstagramIcon from '../../../../../../common/assets/icons/instagram-colorful.svg'
import ConnectContext from '../../../../../../contexts/ConnectContext/ConnectContext'
import { connectInstagram } from '../../../../HomeServices'
import i18n from '../../../../../../languages/i18n'

function InstagramConnectButton() {
  const { setStatusConnect, connectData, network } = useContext(ConnectContext)
  const { t } = useTranslation()
  const facebookLanguage = ['pt', 'pt-BR', 'pt_BR'].includes(i18n.language)
    ? 'pt_BR'
    : i18n.language

  console.log(facebookLanguage)

  const facebookCallback = async (response) => {
    console.log(response)
    if (response.status !== 'unknown') {
      setStatusConnect(t('connect_validating_authorization'))
      connectInstagram(
        connectData.air_id,
        connectData.client_id,
        response.accessToken,
        connectData.source
      )
        .then(() => {
          setStatusConnect(t('connect_success'))
        })
        .catch((error) => {
          setStatusConnect(`Error: ${error.response.data.message}`)
        })
    } else {
      setStatusConnect(t('connect_closed_popup'))
    }
  }

  useEffect(() => {})
  return (
    <>
      <FacebookLogin
        onClick={() => {
          setStatusConnect(t('connect_waiting'))
        }}
        autoLoad={false}
        appId="1009050182802521"
        callback={facebookCallback}
        scope="business_management,pages_read_engagement,instagram_manage_insights,instagram_basic,pages_show_list"
        disableMobileRedirect
        language={facebookLanguage}
        fields="name,email,picture,id"
        render={(renderProps) => (
          <ConnectButton
            icon={InstagramIcon}
            text={t('connect_button_instagram')}
            onClick={renderProps.onClick}
            disabled={network !== 'instagram'}
            backgroundStyles={{
              backgroundImage:
                'linear-gradient(to right, #FFAF1F 0%, 30%, #FE1999 58%, 79%, #9B2ADA 100%)',
            }}
          />
        )}
      />
    </>
  )
}

export default InstagramConnectButton
